import { Badge, Button, message, Modal, Tag } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { MainSubStructureModel, NormalProgramme, request, TimeStampFormatter } from 'egenie-utils';
import React from 'react';
import { ENUM_PLUS_SERVICE_AUDIT_STATUS, ENUM_PLUS_SERVICE_TYPE, getOwner } from '../../utils';
import type { MainItem } from './types';

export class Store {
  constructor() {
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;

    getOwner()
      .then((data) => this.normalProgramme.filterItems.addDict({ ownerIds: data }));
  }

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '批量审核通过',
        permissionId: '21',
        handleClick: () => this.handleApprove(this.mainSubStructureModel.gridModel.selectRows),
      },
    ],
    grid: {
      columns: [
        {
          name: '操作',
          key: 'operate',
          width: 80,
          formatter: ({ row }: { row: MainItem; }) => {
            return (
              <Button
                disabled={row.auditStatus !== ENUM_PLUS_SERVICE_AUDIT_STATUS.approving.value}
                onClick={() => this.handleApprove([row])}
                size="small"
                type="link"
              >
                审核通过
              </Button>
            );
          },
        },
        {
          key: 'ownerName',
          name: '零售商名称',
          width: 200,
        },
        {
          key: 'valueAddedName',
          name: '增值服务',
          width: 200,
          formatter: ({ row }: { row: MainItem; }) => (
            <Tag color="processing">
              {row.valueAddedName}
            </Tag>
          ),
        },
        {
          key: 'auditStatusName',
          name: '审核状态',
          width: 100,
          formatter: ({ row }: { row: MainItem; }) => (
            <Badge

              // @ts-ignore
              status={Object.values(ENUM_PLUS_SERVICE_AUDIT_STATUS)
                .find((val) => val.value === row.auditStatus)?.color}
              text={row.auditStatusName}
            />
          ),
        },
        {
          key: 'applyTime',
          name: '申请时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.applyTime}/>,
        },
        {
          key: 'passTime',
          name: '通过时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.passTime}/>,
        },
        {
          key: 'stopTime',
          name: '终止时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.stopTime}/>,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'wmsOwnerValueAddedId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/plusServiceApprove/index',
    },
    pageId: '60167',

    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/baseinfo/rest/value/added/examine/list',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme: NormalProgramme = new NormalProgramme({
    count: 6,
    filterItems: [
      {
        field: 'ownerIds',
        label: '零售商',
        type: 'select',
        mode: 'multiple',
        isParamList: true,
      },
      {
        field: 'addedValueServiceList',
        label: '增值服务',
        type: 'select',
        mode: 'multiple',
        isParamList: true,
        data: Object.values(ENUM_PLUS_SERVICE_TYPE),
      },
      {
        field: 'auditStatusList',
        label: '审核状态',
        type: 'select',
        mode: 'multiple',
        isParamList: true,
        data: Object.values(ENUM_PLUS_SERVICE_AUDIT_STATUS)
          .map((item) => ({
            ...item,
            value: `${item.value}`,
          })),
      },
    ],
    handleSearch: this.mainSubStructureModel.gridModel.onQuery,
  });

  public handleApprove = (rows: MainItem[]): void => {
    if (rows.length === 0) {
      const error = '请选择数据';
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    if (rows.some((item) => item.auditStatus !== ENUM_PLUS_SERVICE_AUDIT_STATUS.approving.value)) {
      const error = `只有${ENUM_PLUS_SERVICE_AUDIT_STATUS.approving.label}数据支持审核`;
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    Modal.confirm({
      title: '确定审核吗?',
      onOk: async() => {
        await request({
          url: '/api/cloud/baseinfo/rest/value/added/examine',
          method: 'post',
          data: { wmsOwnerValueAddedIdList: rows.map((item) => item.wmsOwnerValueAddedId) },
        });

        message.success('审核成功');
        this.mainSubStructureModel.gridModel.resetAll();
        this.mainSubStructureModel.gridModel.onRefresh();
      },
    });
  };
}
