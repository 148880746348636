import { Card, Layout } from 'antd';
import { NormalProgrammeComponent, MainSubStructure } from 'egenie-utils';
import React from 'react';
import styles from './index.less';
import { Store } from './store';

const store = new Store();
export default function() {
  return (
    <Layout className={styles.container}>
      <Layout.Content>
        <Card size="small">
          <NormalProgrammeComponent store={store.normalProgramme}/>
        </Card>
        <div className={styles.tableWrapper}>
          <MainSubStructure store={store.mainSubStructureModel}/>
        </div>
      </Layout.Content>
    </Layout>
  );
}

